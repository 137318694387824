import { PureQueryOptions } from '@apollo/client';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';

import { Box, Checkbox, Collapse, Flex, Grid, Tag, Text, Tooltip } from '~/components/blocks';
import { QuestionMarkIcon } from '~/components/partials';
import { FollowupQuestionnaireSheetEventOnPatientDetailDialogFragment } from '~/graphql';
import { Label } from '~/utils/label';

import { FollowupQuestionnaireSheetIcon } from '../FollowupQuestionnaireSheetIcon';
import { buildAllChoices } from './questions';
import { useViewSheet } from './use-view-sheet';

type Props = {
  defaultOpen?: boolean;
  readonly?: boolean;
  sheet: FollowupQuestionnaireSheetEventOnPatientDetailDialogFragment;
  refetchQueries?: PureQueryOptions[];
  children?: React.ReactNode;
};

const Root = styled(Collapse)(({ theme }) =>
  css({
    border: `2px solid ${theme.colors.colorPallete.grey03}`,
    borderRadius: '0px 6px 6px 6px',
    padding: theme.space.m,
    transitionDuration: theme.transitions.default,
    '& + &': {
      marginTop: theme.space.l,
    },
    '&:hover': {
      background: theme.colors.background.default,
      boxShadow: theme.shadows.default,
      '& > dt': {
        [`${Text}`]: {
          color: theme.colors.text.primary,
        },
        '& > i': {
          borderLeftColor: theme.colors.text.primary,
        },
      },
    },
    '& > dt': {
      borderBottom: 'none',
      padding: '0',
      '&:hover': {
        background: theme.colors.background.transparent,
      },
    },
  }),
);

const Unread = styled(Tag)(({ theme }) =>
  css({
    color: theme.colors.text.white,
    background: theme.colors.background.secondary,
    border: theme.borders.transparent,
    borderRadius: theme.radii.half,
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeights.bold,
    padding: `${theme.space.xs} ${theme.space.s}`,
    lineHeight: 1,
    width: '35px',
    marginRight: theme.space.l,
  }),
);

const AnswerGrid = styled(Grid)(({ theme }) =>
  css({
    '&:last-child': {
      marginBottom: theme.space.m,
    },
  }),
);

const CustomCheckBoxFlex = styled(Flex)(({ theme }) =>
  css({
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    justifyContent: 'flex-end',
    marginRight: theme.space.m,
    marginBottom: theme.space.m,
    marginTop: theme.space.m,
  }),
);

const CheckItem = styled('li')(({ theme }) =>
  css({
    listStyleType: 'none',
    paddingLeft: `calc(${theme.space.m} + ${theme.space.s})`,
    position: 'relative',
    '&::before': {
      content: '""',
      top: '0.6em',
      width: '10px',
      height: '5px',
      position: 'absolute',
      color: theme.colors.border.grey,
      borderLeft: theme.borders.black,
      borderBottom: theme.borders.black,
      transform: 'rotate(-45deg)',
    },
  }),
);

export const FollowupQuestionnaireSheetEvent: React.FC<Props> = (props: Props) => {
  const { sheet } = props;

  const theme = useTheme();
  const { isViewing, view } = useViewSheet(props.refetchQueries);

  const [isUnread, setIsUnread] = useState(!sheet.medicationFollowupMessage.isRead);
  const [isOpen, setIsOpen] = useState(props.defaultOpen ?? false);
  const [checkedViewAllAnswer, setCheckedViewAllAnswer] = useState(false);

  const answeredAt = `${Label.YYYYMMDDja(sheet.createdAt)} ${Label.HHMM(sheet.createdAt)}`;
  const subject = sheet.medicationFollowupMessage.medicationFollowupQuestionnaireRevision?.subject;

  const sections =
    sheet.medicationFollowupMessage.medicationFollowupQuestionnaireRevision?.formData.sections;
  const allAnswers = sections ? buildAllChoices(sections) : [];
  const hasOnlyText = allAnswers.length === 0;
  const handleClick = useCallback(async () => {
    if (isViewing) return;

    setIsOpen((_state) => !_state);

    if (!isUnread) return;

    try {
      await view(sheet.id);
      setIsUnread(false);
    } catch {
      // 何もしない
    }
  }, [isUnread, isViewing, sheet.id, view]);

  return (
    <Flex alignItems="top">
      <Tooltip
        content={
          <Text color="white" size="xs" fontWeight="bold">
            患者が回答
          </Text>
        }
      >
        <Flex marginLeft={theme.space.xs}>
          <FollowupQuestionnaireSheetIcon icon="people" color="black" size="xl" />
        </Flex>
      </Tooltip>
      <Flex flexDirection="column" width="100%" marginLeft={theme.space.m}>
        <Text size="xs">{answeredAt}</Text>
        <Root
          open={isOpen}
          label={
            <Flex>
              <Text block size="s" marginRight={theme.space.s} lineHeight={1.3}>
                質問票{subject && `（${subject}）`}の回答が届きました
              </Text>
              <Flex alignItems="center">
                {isUnread && (
                  <Flex alignItems="center">
                    <Box>
                      <Unread size="s">未読</Unread>
                    </Box>
                  </Flex>
                )}
              </Flex>
            </Flex>
          }
          onClick={handleClick}
        >
          {!hasOnlyText && (
            <CustomCheckBoxFlex onClick={() => setCheckedViewAllAnswer(!checkedViewAllAnswer)}>
              <Checkbox name="isAllView" checked={checkedViewAllAnswer} />
              <Text ml={theme.space.s} size="s">
                全選択肢を表示
              </Text>
            </CustomCheckBoxFlex>
          )}

          {sheet.answers.map(({ title, value }, idx) => {
            return (
              <AnswerGrid
                key={idx}
                margin={`0 ${theme.space.s} ${theme.space.l}`}
                gridTemplateColumns="min-content ifr"
              >
                <Flex marginTop={theme.space.m}>
                  <QuestionMarkIcon />
                  <Text size="s">{title}</Text>
                </Flex>
                {checkedViewAllAnswer && allAnswers.find((a) => a.title === title)
                  ? allAnswers
                      .find((a) => a.title === title)
                      ?.values.map((v) => {
                        return value.includes(v) ? (
                          <CheckItem>
                            <Text key={v} fontWeight="bold" paddingLeft={theme.space.l} size="s">
                              {v}
                            </Text>
                          </CheckItem>
                        ) : (
                          <Text
                            key={v}
                            block
                            paddingLeft={`calc(${theme.space.xl} + ${theme.space.s})`}
                            size="s"
                            color="grey"
                          >
                            {v}
                          </Text>
                        );
                      })
                  : value.map((v, idx) => {
                      return (
                        <Text
                          paddingLeft={theme.space.xl}
                          key={idx}
                          block
                          fontWeight="bold"
                          size="s"
                          marginLeft={theme.space.s}
                          marginTop={theme.space.s}
                          whiteSpace="pre-wrap"
                        >
                          {v}
                        </Text>
                      );
                    })}
              </AnswerGrid>
            );
          })}
          {props.children}
        </Root>
      </Flex>
    </Flex>
  );
};

FollowupQuestionnaireSheetEvent.displayName = 'FollowupQuestionnaireSheetEvent';
