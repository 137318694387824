import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Checkbox as DefaultCheckbox, Flex, Text } from '~/components/blocks';

type Props = {
  checked: boolean;
  onChange: () => void;
};

const Checkbox = styled(DefaultCheckbox)(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
    fontWeight: theme.fontWeights.bold,
    marginTop: theme.space.l,
  }),
);

const CheckboxSubText = styled(Text)(({ theme }) =>
  css({
    fontSize: theme.fontSizes.xs,
    color: theme.colors.text.default,
    marginLeft: `calc(${theme.space.xl} + ${theme.space.s})`,
    marginTop: '-0.3rem',
  }),
);

export const ChargeNoAmountCheckBox = React.memo((props: Props) => {
  return (
    <Flex flexDirection="column" alignItems="flex-start">
      <Checkbox
        name="isNoAmount"
        label="請求金額の入力をスキップ"
        onChange={props.onChange}
        checked={props.checked}
      />
      <CheckboxSubText>請求金額は「0円」となります</CheckboxSubText>
    </Flex>
  );
});

ChargeNoAmountCheckBox.displayName = 'ChargeNoAmountCheckBox';
