import { useEffect } from 'react';

import { useGetOutpatientQuestionnaireSettingLazyQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';

export const useFetchOutpatientQuestionnaireSetting = () => {
  const [fetchOutpatientQuestionnaireSetting, { data, loading, error, called }] =
    useGetOutpatientQuestionnaireSettingLazyQuery();

  const me = data ? getMe(data) : null;
  const active = me?.organization.outpatientQuestionnaireSetting?.active;
  const organizationId = me?.organization.id;
  const outpatientQuestionnaireOrganizationId =
    me?.organization.outpatientQuestionnaireSetting?.outpatientQuestionnaireOrganization.id;

  useEffect(() => {
    fetchOutpatientQuestionnaireSetting();
  }, [fetchOutpatientQuestionnaireSetting]);

  return {
    loading: loading || !called,
    error,
    active,
    organizationId,
    outpatientQuestionnaireOrganizationId,
  };
};
