import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { FormikProps } from 'formik';
import React, { useCallback } from 'react';
import { compose, variant } from 'styled-system';

import { Box, Radio, RadioGroup, Text } from '~/components/blocks';
import { Label } from '~/utils/label';

import { Fields } from '../types';
import { PaymentMethodExplanation } from './constants';

type Props = {
  formik: FormikProps<Fields>;
  disabled: boolean;
};

const CustomBox = styled(Box, { shouldForwardProp })<{ border: 'primary' | 'default' }>(
  ({ theme, onClick }) =>
    css({
      borderRadius: theme.radii.default,
      padding: theme.space.m,
      '&:first-of-type': {
        marginBottom: theme.space.m,
      },
      ...(onClick && {
        cursor: 'pointer',
      }),
    }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'border',
        variants: {
          primary: css({
            border: theme.borders.primary,
          }),
          default: css({
            border: theme.borders.default,
          }),
        },
      }),
    ),
);

const CustomRadio = styled(Radio)(() =>
  css({
    fontWeight: 'bold',
  }),
);

const RadioSubText = styled(Text)(({ theme }) => {
  return css({
    marginLeft: `calc(${theme.space.xl} + ${theme.space.s})`,
    fontSize: theme.fontSizes.xs,
  });
});

export const PaymentMethodRadioGroup = React.memo(({ formik, disabled }: Props) => {
  const handleClickApp = useCallback(() => {
    if (formik.values.isNoAmount) {
      formik.setValues({ ...formik.initialValues, paymentMethod: 'app' });
    } else {
      formik.setFieldValue('paymentMethod', 'app');
    }
  }, [formik]);

  return (
    <RadioGroup orientation="vertical">
      <CustomBox
        border={formik.values.paymentMethod === 'cash' ? 'primary' : 'default'}
        onClick={
          disabled
            ? undefined
            : () => {
                formik.setFieldValue('paymentMethod', 'cash');
              }
        }
      >
        <CustomRadio
          label={Label.paymentMethod('cash')}
          name="paymentMethod"
          value="cash"
          disabled={disabled}
          checked={formik.values.paymentMethod === 'cash'}
          onChange={formik.handleChange}
        />
        <RadioSubText>{PaymentMethodExplanation.cash}</RadioSubText>
      </CustomBox>
      <CustomBox
        border={formik.values.paymentMethod === 'app' ? 'primary' : 'default'}
        onClick={disabled ? undefined : handleClickApp}
      >
        <CustomRadio
          label={Label.paymentMethod('app')}
          name="paymentMethod"
          value="app"
          disabled={disabled}
          checked={formik.values.paymentMethod === 'app'}
          onChange={formik.handleChange}
        />
        <RadioSubText>{PaymentMethodExplanation.app}</RadioSubText>
      </CustomBox>
    </RadioGroup>
  );
});

PaymentMethodRadioGroup.displayName = 'PaymentMethodRadioGroup';
