import { useTheme } from '@emotion/react';
import React, { useCallback } from 'react';

import { Button, Text } from '~/components/blocks';
import { ExternalLink } from '~/components/blocks/ExternalLink';
import { Notice } from '~/components/layouts/Notice';

type Props = {
  redirectUrl: string | null;
};

export const ClientCertError = (props: Props) => {
  const theme = useTheme();
  const { redirectUrl } = props;
  const handleClick = useCallback(() => {
    if (redirectUrl) {
      window.location.href = `${redirectUrl}settings/organization/client_certs`;
    }
  }, [redirectUrl]);

  return (
    <Notice>
      <Text as="h2" size="xl" mb={theme.space.xxl}>
        クライアント証明書の検証に失敗しました
      </Text>
      <Text as="p" lineHeight="xl">
        クライアント証明書が正しくインストールされていないか、
        <br />
        有効期限が切れている可能性があります。
        <br />
        お使いの端末にインストールされている証明書の情報を
        <br />
        ご確認ください。
      </Text>
      {redirectUrl && (
        <>
          <Text mb={theme.space.xxl}>
            インストール方法は
            <ExternalLink url="https://intercom.help/pharms/ja/articles/6151561-%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E8%AA%8D%E8%A8%BC%E3%82%92%E6%9C%89%E5%8A%B9%E5%8C%96%E3%81%99%E3%82%8B">
              こちら
            </ExternalLink>
          </Text>
          <Button use="base" size="l" align="center" onClick={handleClick}>
            クライアント証明書ページへ
          </Button>
        </>
      )}
    </Notice>
  );
};
