import {
  ClientCertDetailFragment,
  ErrorMedicationFollowupMessageFragment,
  OrganizationNotificationFragment,
  OrganizationNotificationOnAgentNotificationsFragment,
  PreparedTelemedicineAppointmentFragment,
} from '~/graphql';

export type MedicationFollowupNotificationPanelState = {
  page: number;
  perPage: number;
  totalPage: number;
  totalCount: number;
};

export type NotificationPanelState = {
  isOpen: boolean;
};

export type Logout = {
  isLogout: boolean;
};

export type Notification = {
  followupCount: number;
  receptionCount: number;
  entryCount: number;
  medicineNoteCount: number;
};

export type NotificationSettings = {
  isLightPlan: boolean;
  isFollowupEnabled: boolean;
  isOnlineEnabled: boolean;
};

export type TelemedicineAppointments = {
  isEnabled: boolean;
  appointments: PreparedTelemedicineAppointmentFragment[];
};

const TwoFactorAuthPanelMap = {
  Activating: 'activating',
  ActivationCompleted: 'completed',
  Introduction: 'introduction',
  ScanQrCode: 'scan',
  SendCode: 'send',
};

export type TwoFactorAuthenticationModal = {
  isOpen: boolean;
  isReset: boolean;
  currentPanel: (typeof TwoFactorAuthPanelMap)[keyof typeof TwoFactorAuthPanelMap];
  otpSecretId: string | null;
  qrCodeData: string | null;
};

export type FaximoSendFaxErrorNotification = {
  faximoSendFaxId: string;
  organizationNotificationId: string;
  createdAt: string;
};

export type FaximoSendFaxErrors = {
  loaded: boolean;
  notifications: Array<FaximoSendFaxErrorNotification>;
};

export type ClientAuthenticationModal = {
  isClosed: boolean;
};

export type FollowupMessageErrorNotification = {
  followupMessage: ErrorMedicationFollowupMessageFragment;
  organizationNotificationId: string;
  createdAt: string;
};

export type FollowupMessageErrors = {
  loaded: boolean;
  notifications: Array<FollowupMessageErrorNotification>;
};

export type UberDeliveryNotifications = {
  loaded: boolean;
  notifications: Array<OrganizationNotificationFragment>;
  needPlaySoundNotificationCount: number;
};

export type AgentNotifications = {
  loaded: boolean;
  notifications: Array<OrganizationNotificationOnAgentNotificationsFragment>;
};

export const RenewableTarget = {
  practitioner: 'practitioner',
  organization: 'organization',
  company: 'company',
} as const;

export type RenewableTargetType = (typeof RenewableTarget)[keyof typeof RenewableTarget];

export type RenewClientCertModal = {
  isOpen: boolean;
  isClosed: boolean;
  isAdmin: boolean;
  isCompanyAdmin: boolean;
  renewableTarget: RenewableTargetType | null;
  clientCert: ClientCertDetailFragment | null;
  practitionerId: string;
};
