import { fromUnixTime } from 'date-fns';
import type Firebase from 'firebase/compat';
import { useCallback, useEffect, useState } from 'react';

import { SubscribeChannelDocument, UnsubscribeChannelDocument } from '~/graphql';
import { apolloClient } from '~/graphql/with-apollo';

import { useFirebase } from './use-firebase';

/**
 * Firebaseに保存されてるデータ構造
 *
 * パス
 * ./channels/[firebaseUid]/events
 *
 * オブジェクト
 * {
 *   time: number;
 *   actionName: string;
 *   typeName: string;
 *   typeId: string;
 * }
 */

type ResouceType =
  | 'Announcement'
  | 'Appointment'
  | 'Charge'
  | 'CheckinEntry'
  | 'ClientCertJobInformation'
  | 'Encounter'
  | 'FaximoSendFax'
  | 'GenerateTracingReport'
  | 'HiccupTemporaryPermission'
  | 'HiccupOnetimeApiCache'
  | 'MedicationFollowupQuestionnaireSheet'
  | 'OrganizationAuditEventCsv'
  | 'CompanyReceptionsCsv'
  | 'OrganizationImportJobInformation'
  | 'OrganizationNotification'
  | 'OutpatientQuestionnaireEntry'
  | 'Visit'
  | 'DraftAppointment'
  | 'UberOrganizationSettingJobInformation'
  | 'Agent'
  | 'MedicationFollowupMessageTemplateJobInformation';

type ActionName = 'created' | 'updated' | 'deleted' | 'prepared_telemedicine';

type ChannelOriginalEvent = {
  time: number;
  actionName: ActionName;
  typeName: ResouceType;
  typeId: string;
};

export type ChannelEvent = {
  time: Date;
  actionName: ActionName;
  typeName: ResouceType;
  typeId: string;
};

type ConnectProps = {
  db: Firebase.database.Database;
  firebaseUid: string;
};

type Clear = () => void;

type SubscriptionFunc = (event: ChannelEvent) => void;

/**
 * Firebaseのチャンネル管理
 */
const ChannelManager = (() => {
  let channelRef: Firebase.database.Reference | null = null;
  let subscriptions: SubscriptionFunc[] = [];
  const disconnect = async () => {
    if (!channelRef) return null;

    window.removeEventListener('beforeunload', disconnect);

    const channelId = channelRef.key;

    // サーバーに登録している購読チャンネル削除
    if (apolloClient && channelId) {
      await apolloClient.mutate({
        mutation: UnsubscribeChannelDocument,
        variables: {
          input: {
            channelId,
          },
        },
      });
    }

    // 切断処理をキャンセルする
    channelRef.onDisconnect().cancel();
    // チャンネルを削除する
    channelRef.remove();
    channelRef = null;

    console.log('[Subscription] チャンネル削除');

    return channelId;
  };
  const connect = async (props: ConnectProps) => {
    if (!channelRef) {
      channelRef = props.db.ref(`channels/${props.firebaseUid}`).push();

      // channel作成
      await channelRef.set({ time: Date.now() });

      // channel削除（ブラウザを閉じたり新しいページに移動した場合にsetしたデータを削除）
      channelRef.onDisconnect().remove();

      // チャンネル内のイベント講読
      const eventsRef = channelRef.child('events');
      eventsRef.on('child_added', (snapshot) => {
        const event = snapshot.toJSON() as ChannelOriginalEvent;
        console.log('[Subscription] イベント発生', event);
        for (const subscription of subscriptions) {
          subscription({
            ...event,
            time: fromUnixTime(event.time),
          });
        }
      });

      // サーバーに購読チャンネルセット
      if (apolloClient && channelRef.key) {
        await apolloClient.mutate({
          mutation: SubscribeChannelDocument,
          variables: {
            input: {
              channelId: channelRef.key,
            },
          },
        });
      }

      window.removeEventListener('beforeunload', disconnect);
      window.addEventListener('beforeunload', disconnect);

      console.log(`[Subscription] チャンネル作成 ${channelRef.key}`);

      return [channelRef.key, false] as const;
    } else {
      return [channelRef.key, true] as const;
    }
  };
  const subscribe = (subscription: SubscriptionFunc) => {
    if (!subscriptions.includes(subscription)) {
      subscriptions = [...subscriptions, subscription];
    }
  };
  const unsubscribe = (subscription: SubscriptionFunc) => {
    subscriptions = subscriptions.filter((_subscription) => _subscription !== subscription);
  };

  return {
    connect,
    disconnect,
    subscribe,
    unsubscribe,
  };
})();

export const useChannel = () => {
  const { auth, db } = useFirebase();
  const firebaseUid = auth.currentUser?.uid;
  const connect = useCallback(async () => {
    if (!firebaseUid) return;

    await ChannelManager.connect({
      db,
      firebaseUid,
    });
  }, [db, firebaseUid]);

  const disconnect = useCallback(async () => {
    await ChannelManager.disconnect();
  }, []);

  useEffect(() => {
    connect();
  }, [connect]);

  return {
    subscribe: ChannelManager.subscribe,
    unsubscribe: ChannelManager.unsubscribe,
    /**
     * RealtimeDBとの接続がきれるので基本的にログアウト時にしか使わない
     */
    disconnect,
  };
};

export const useSubscriptions = (resouce: ResouceType, actions: ActionName[]) => {
  const { subscribe, unsubscribe } = useChannel();
  const [event, setEvent] = useState<ChannelEvent | null>(null);
  const handleEvent = useCallback(
    (_event: ChannelEvent) => {
      if (_event.typeName === resouce && actions.includes(_event.actionName)) {
        setEvent(_event);
      }
    },
    [resouce, actions],
  );
  const clear: Clear = useCallback(() => {
    setEvent(null);
  }, []);

  useEffect(() => {
    subscribe(handleEvent);

    return () => {
      unsubscribe(handleEvent);
    };
  }, [handleEvent, subscribe, unsubscribe]);

  return [event, clear] as const;
};
