import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';

import { ColorPalette, MonoColor } from '~/styles/theme';
import { Size } from '~/styles/types';

type Props = {
  children: React.ReactNode;
  url: string;
  color?: keyof typeof ColorPalette | keyof typeof MonoColor;
  fontSize?: Size;
  fontWeight?: 'normal' | 'bold';
};

const Link = styled('a', { shouldForwardProp })<Props>(({ color, fontSize, fontWeight }) =>
  css({
    textDecoration: 'underline',
    color: color
      ? ColorPalette[color as keyof typeof ColorPalette] ||
        MonoColor[color as keyof typeof MonoColor]
      : ColorPalette.linkBlue,
    ...(fontSize && {
      fontSize: fontSize,
    }),
    fontWeight: fontWeight ? fontWeight : 'normal',
    ':hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  }),
);

export const ExternalLink: React.FC<Props> = (props) => {
  return (
    <Link {...props} target="_blank" rel="noopener noreferrer" href={props.url}>
      {props.children}
    </Link>
  );
};
