import React from 'react';

import { Tabs } from '~/components/blocks';
import { PlanFeatureCode } from '~/graphql';
import { useCompanyFeatureFlag } from '~/hooks/use-feature-flag';
import { usePlanFeature } from '~/hooks/use-plan-feature';

import { HrefProps } from '../types';
import { MenuLink } from './MenuLink';

const MENU_ROUTES: { [key: string]: [HrefProps, ...HrefProps[]] } = {
  organization: [{ path: '/settings/organization' }, { path: '/settings', exact: true }],
  auditevents: [{ path: '/settings/auditevents' }],
  systemLinkage: [{ path: '/settings/systems' }],
  practitioners: [{ path: '/settings/practitioners' }],
  fees: [{ path: '/settings/fees' }],
  messages: [
    { path: '/settings/messages' },
    { path: '/settings/messages/message_templates', exact: true },
  ],
  shippings: [{ path: '/settings/shippings' }],
  systemSettings: [{ path: '/settings/system_settings' }],
  medicationFollowup: [{ path: '/settings/medication_followup' }],
  medicalInstitutions: [{ path: '/settings/medical_institutions' }],
  agent: [{ path: '/settings/agent' }],
  outpatientQuestionnaires: [{ path: '/settings/outpatient_questionnaires' }],
};

export const SETTING_TITLES = {
  organization: '店舗管理',
  practitioners: 'スタッフ管理',
  fees: '料金設定',
  shippings: '配達設定',
  messages: 'フォローアップ設定',
  medicalInstitutions: '連絡先設定',
  outpatientQuestionnaires: 'Web問診設定',
  systemLinkage: 'システム連携',
  agent: '連携ソフト設定',
  systemSettings: 'その他設定',
  auditevents: '監査ログ',
} as const;

export const RootMenu = () => {
  const { hasEnabledFeature: hasEnabledFollowup } = usePlanFeature(PlanFeatureCode.Followup);
  // 特定法人に対しては機能を非公開化し続けるため、リリース後も削除しないこと（ref. https://github.com/medley-inc/pharms/pull/9011 ）
  const { isEnabled: agentSettingEnabled } = useCompanyFeatureFlag(
    'Issue7579-enable-agent-setting',
  );
  const { hasEnabledFeature: hasEnabledOutpatientQuestionnaire } = usePlanFeature(
    PlanFeatureCode.OutpatientQuestionnaire,
  );

  return (
    <Tabs orientation="vertical">
      <MenuLink href={MENU_ROUTES.organization}>{SETTING_TITLES.organization}</MenuLink>
      <MenuLink href={MENU_ROUTES.practitioners}>{SETTING_TITLES.practitioners}</MenuLink>
      <MenuLink href={MENU_ROUTES.fees}>{SETTING_TITLES.fees}</MenuLink>
      <MenuLink href={MENU_ROUTES.shippings}>{SETTING_TITLES.shippings}</MenuLink>
      {hasEnabledFollowup && (
        <>
          <MenuLink href={MENU_ROUTES.messages}>{SETTING_TITLES.messages}</MenuLink>
          <MenuLink href={MENU_ROUTES.medicalInstitutions}>
            {SETTING_TITLES.medicalInstitutions}
          </MenuLink>
        </>
      )}
      {process.env.enabledSystemLinkage && (
        <MenuLink href={MENU_ROUTES.systemLinkage}>{SETTING_TITLES.systemLinkage}</MenuLink>
      )}
      {hasEnabledOutpatientQuestionnaire && (
        <MenuLink href={MENU_ROUTES.outpatientQuestionnaires}>
          {SETTING_TITLES.outpatientQuestionnaires}
        </MenuLink>
      )}
      {agentSettingEnabled && <MenuLink href={MENU_ROUTES.agent}>{SETTING_TITLES.agent}</MenuLink>}
      <MenuLink href={MENU_ROUTES.systemSettings}>{SETTING_TITLES.systemSettings}</MenuLink>
      <MenuLink href={MENU_ROUTES.auditevents}>{SETTING_TITLES.auditevents}</MenuLink>
    </Tabs>
  );
};
