import { array, boolean, mixed, object, ObjectSchema, Schema, string } from 'yup';

import { LineItem, OptionalLineItem } from '~/components/partials';
import { FormErrorMessage } from '~/constants/messages';
import { numberRegexp } from '~/constants/regexp';
import { AppointmentDeliveryMethod, LineItemCode } from '~/graphql';

import { Fields } from './types';

export const validationLineItemSchema: ObjectSchema<LineItem> = object({
  code: string()
    .label('種別')
    .required(FormErrorMessage.required)
    .equals(Object.values(LineItemCode)) as Schema<LineItemCode>,
  subject: string().label('項目名').required(FormErrorMessage.required),
  amount: string()
    .label('金額')
    .required(FormErrorMessage.required)
    .min(0)
    .matches(numberRegexp, FormErrorMessage.number),
});

export const validationOptionalLineItemSchema: ObjectSchema<OptionalLineItem> = object({
  feeSettingId: string().label('項目ID').required(FormErrorMessage.required),
  code: string()
    .label('種別')
    .required(FormErrorMessage.required)
    .equals([...Object.values(LineItemCode), '']) as Schema<LineItemCode>,
  subject: string().label('項目名').required(FormErrorMessage.required),
  amount: string()
    .label('金額')
    .required(FormErrorMessage.required)
    .default('')
    .min(0)
    .matches(numberRegexp, FormErrorMessage.number),
});

export const validationSchema: ObjectSchema<Fields> = object({
  paymentMethod: string().label('支払い方法').oneOf(['cash', 'app']).required(),
  lineItem: validationLineItemSchema,
  optionalLineItems: array()
    .of(validationOptionalLineItemSchema)
    .label('請求金額')
    .default([])
    .required(FormErrorMessage.required),
  deliveryMethod: mixed()
    .label('配達方法')
    .equals(Object.values(AppointmentDeliveryMethod))
    .required(FormErrorMessage.required) as Schema<AppointmentDeliveryMethod>,
  isNoAmount: boolean().label('請求金額の入力をスキップ').required(FormErrorMessage.required),
});
