import {
  DraftAppointment,
  DraftAppointmentCancellationReason,
  DraftAppointmentStatus,
} from '~/graphql';

type CanceledByType = '薬局' | '医療機関' | '患者' | '不明';

export const translateReason = (
  draftAppointment: Pick<DraftAppointment, 'status' | 'cancellationReason'>,
): { canceledBy: CanceledByType; detail: string } => {
  if (draftAppointment.status === DraftAppointmentStatus.NotPrescribed) {
    return { canceledBy: '医療機関', detail: '処方箋発行なし' };
  }

  switch (draftAppointment.cancellationReason) {
    case DraftAppointmentCancellationReason.Prov:
      return { canceledBy: '薬局', detail: 'お薬の在庫がなかった' };
    case DraftAppointmentCancellationReason.Pat:
      return { canceledBy: '薬局', detail: '患者と連絡が取れない' };
    case DraftAppointmentCancellationReason.PatCpp:
      return { canceledBy: '患者', detail: '患者からキャンセルされた' };
    case DraftAppointmentCancellationReason.OthMu:
      return { canceledBy: '薬局', detail: '医療機関が処方箋を発行しなかった' };
    case DraftAppointmentCancellationReason.Other:
      return { canceledBy: '薬局', detail: 'その他' };
    default:
      return { canceledBy: '不明', detail: '不明' };
  }
};

export const cancellationReasons = {
  [DraftAppointmentStatus.Available]: [
    DraftAppointmentCancellationReason.OthMu,
    DraftAppointmentCancellationReason.Prov,
    DraftAppointmentCancellationReason.Other,
  ],
  [DraftAppointmentStatus.WaitForBooking]: [
    DraftAppointmentCancellationReason.Pat,
    DraftAppointmentCancellationReason.Prov,
    DraftAppointmentCancellationReason.Other,
  ],
};
