import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Box, Text } from '~/components/blocks';
import { Label } from '~/utils/label';

import { Fields } from '../types';
import { PaymentMethodExplanation } from './constants';

type Props = {
  paymentMethod: Fields['paymentMethod'];
};

const CustomBox = styled(Box)(({ theme }) =>
  css({
    borderRadius: theme.radii.default,
    padding: theme.space.m,
    border: theme.borders.default,
  }),
);

export const PaymentMethodBox = React.memo(({ paymentMethod }: Props) => {
  const theme = useTheme();
  const paymentMethodLabel = Label.paymentMethod(paymentMethod);

  return paymentMethod === 'cash' ? (
    <CustomBox>
      <Text size="m" fontWeight={theme.fontWeights.bold}>
        {paymentMethodLabel}
      </Text>
      <Text size="xs">{PaymentMethodExplanation.cash}</Text>
    </CustomBox>
  ) : (
    <CustomBox>
      <Text size="m" fontWeight={theme.fontWeights.bold}>
        {paymentMethodLabel}
      </Text>
      <Text size="xs">{PaymentMethodExplanation.app}</Text>
    </CustomBox>
  );
});

PaymentMethodBox.displayName = 'PaymentMethodBox';
