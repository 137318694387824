import { css } from '@styled-system/css';

import { ThemeType } from '~/styles/types';

export const styles = (theme: ThemeType) =>
  css(() => ({
    html: {
      ['@supports (-webkit-touch-callout: none)']: {
        [`@media ${theme.mediaQueries.sp}`]: {
          height: '-webkit-fill-available',
        },
      },
    },

    body: {
      [`@supports (-webkit-touch-callout: none)`]: {
        [`@media ${theme.mediaQueries.sp}`]: {
          minHeight: '100vh',
          height: '-webkit-fill-available',
        },
      },
    },
  }));
