import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { compose, variant } from 'styled-system';

import { Alert, Button, Flex, Icon, Text } from '~/components/blocks';
import { AppBar } from '~/components/blocks/AppBar';
import { CancelPane } from '~/components/partials';
import {
  AppointmentStatus,
  CancelAppointmentReason,
  PrescriptionPreviewFooterAppointmentFragment,
} from '~/graphql';
import { cancelPane } from '~/state/partials/cancel_pane/atoms';

type Props = {
  booking: boolean;
  canceling: boolean;
  previewing: boolean;
  printable: boolean;
  error: string | null;
  appointment: PrescriptionPreviewFooterAppointmentFragment;
  onBook: () => void;
  onCancel: (reason: CancelAppointmentReason) => Promise<void>;
};

const ActionButton = styled(Button)<{ width?: string }>(
  ({ width, theme }) =>
    css({
      width: width || '120px',
      marginLeft: theme.space.l,
      '&:hover': {
        color: theme.colors.colorPallete.grey03,
      },
    }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'use',
        variants: {
          base: css({
            background: theme.colors.background.transparent,
          }),
          white: css({
            background: theme.colors.background.bg,
          }),
        },
      }),
    ),
);

export const PrescriptionPreviewFooter = React.memo((props: Props) => {
  const { booking, canceling, previewing, printable, error, appointment } = props;

  const theme = useTheme();

  const { isOpen } = useRecoilValue(cancelPane);

  const isBookable = appointment.status === AppointmentStatus.Pending && previewing;
  const isCancelable = appointment.status === AppointmentStatus.Pending;
  const isPrintable = printable && !isBookable && previewing;

  const handleClick = useRecoilCallback(
    ({ set }) =>
      () => {
        if (appointment.id) {
          set(cancelPane, {
            isOpen: true,
          });
        }
      },
    [appointment.id],
  );
  const handleClose = useCallback(() => {
    window.close();
  }, []);
  const handlePrint = useCallback(() => window.print(), []);

  return isBookable || isCancelable || isPrintable ? (
    <AppBar
      alignContent="center"
      alignItems="center"
      flexWrap={error ? 'wrap' : 'nowrap'}
      paddingY={theme.space.m}
      paddingX={theme.space.l}
    >
      {error && (
        <Flex width="100%" justifyContent="center">
          <Alert>{error}</Alert>
        </Flex>
      )}
      {(isBookable || isCancelable) && (
        <>
          <CancelPane
            align="left"
            canceling={canceling}
            onCancel={props.onCancel}
            draftAppointmentId={appointment.draftAppointment?.id}
          />
          <Flex width="100%" justifyContent="flex-end">
            <ActionButton use="base" width="60px" onClick={handleClose}>
              閉じる
            </ActionButton>
            <ActionButton use="white" width="140px" disabled={booking} onClick={handleClick}>
              受付をキャンセル
            </ActionButton>
            {isBookable && (
              <Flex alignItems="center">
                <ActionButton
                  use="primary"
                  disabled={isOpen}
                  loading={booking}
                  onClick={props.onBook}
                >
                  受付
                </ActionButton>
              </Flex>
            )}
          </Flex>
        </>
      )}
      {isPrintable && (
        <Flex width="100%" justifyContent="space-between">
          <Flex justifyContent="center" alignItems="center">
            <Icon size="m" color="white" icon="info" />
            <Text color="white" fontWeight="bold" size="xs" marginLeft={theme.space.s}>
              印刷をすると、希望日時・服薬指導方法・問診内容が出力されます
            </Text>
          </Flex>
          <Flex>
            <ActionButton use="base" width="60px" disabled={canceling} onClick={handleClose}>
              閉じる
            </ActionButton>
            <ActionButton onClick={handlePrint}>印刷</ActionButton>
          </Flex>
        </Flex>
      )}
    </AppBar>
  ) : null;
});

PrescriptionPreviewFooter.displayName = 'PrescriptionPreviewFooter';
