import { PlanFeatureCode, useGetCheckinSettingQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';

import { usePlanFeature } from './use-plan-feature';

export const useCheckinSetting = () => {
  const { hasEnabledFeature: hasEnabledCheckin } = usePlanFeature(PlanFeatureCode.Checkin);
  const { data, loading, error, refetch } = useGetCheckinSettingQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !hasEnabledCheckin,
  });
  const me = data ? getMe(data) : null;
  const isActive = !!me?.organization.checkinSetting?.active;
  const checkinOrganizationId = me?.organization.checkinSetting?.checkinOrganization.id || null;
  const checkinOrganizationName = me?.organization.checkinSetting?.checkinOrganization.name ?? null;

  return {
    loading,
    error,
    isActive,
    checkinOrganizationId,
    checkinOrganizationName,
    refetchCheckinSetting: refetch,
  };
};
