import React from 'react';
import { useRecoilValue } from 'recoil';

import { Grid } from '~/components/blocks';
import { MedicineNoteNotificationFooter } from '~/components/partials/MedicineNoteNotificationFooter';
import { MedicineNoteNotificationList } from '~/components/partials/MedicineNoteNotificationList';
import { OnetimeCode } from '~/components/partials/OnetimeCode';
import { QrCodeReaderDialog } from '~/components/partials/QrCodeReaderDialog';
import { medicineNoteNotificationPanelState } from '~/state/partials/medicine_note_notification_panel/atoms';

import { NotificationPanelBase } from '../NotificationPanelBase';

export const MedicineNoteNotificationPanel = () => {
  const { totalCount } = useRecoilValue(medicineNoteNotificationPanelState);

  return (
    <>
      <NotificationPanelBase>
        <NotificationPanelBase.Content>
          <Grid overflow="auto" height="100%" gridTemplateRows="min-content 1fr">
            <OnetimeCode />
            <MedicineNoteNotificationList />
          </Grid>
        </NotificationPanelBase.Content>
        {totalCount > 0 && (
          <NotificationPanelBase.Footer>
            <MedicineNoteNotificationFooter />
          </NotificationPanelBase.Footer>
        )}
      </NotificationPanelBase>
      <QrCodeReaderDialog />
    </>
  );
};
