import { useTheme } from '@emotion/react';
import React from 'react';
import { SpaceProps } from 'styled-system';

import { Box, Text } from '~/components/blocks';
import { ClipButton, Interview } from '~/components/partials';
import { OutpatientQuestionnaireSheetAnswer } from '~/graphql';
import { Label } from '~/utils/label';

type Props = {
  enableCopy?: boolean;
  enteredAt: string;
  answers: Array<OutpatientQuestionnaireSheetAnswer>;
} & SpaceProps;

const buildText = (enteredAt: string, questionnaires: OutpatientQuestionnaireSheetAnswer[]) => {
  const enteredDate = `【回答日】${enteredAt}`;
  const answersText = questionnaires.map(({ title, answers }) => {
    return `Q：${title}
${answers.join('｜')}`;
  });
  return `${enteredDate}
${answersText.join('\n')}`;
};

export const OutpatientQuestionnaireSheet = React.memo((props: Props) => {
  const theme = useTheme();
  const { enableCopy, enteredAt, answers } = props;
  const updatedAt = `最終更新日: ${Label.YYYYMMDDja(enteredAt)}`;

  const clipboardText = buildText(
    `${Label.YYYYMMDDja(enteredAt)} ${Label.HHMM(enteredAt)}`,
    answers,
  );

  return (
    <Box padding={theme.space.l} overflow="auto">
      <Text color="grey01" size="s" mb={theme.space.m}>
        {updatedAt}
      </Text>
      {answers.map((q, i) => (
        <Interview key={i}>
          <Interview.Title>
            <Interview.Icon>Q</Interview.Icon>
            {q.title}
          </Interview.Title>
          <Interview.Contents>
            {q.answers.map((v, l) => (
              <Text block whiteSpace="pre-wrap" key={l}>
                {v}
              </Text>
            ))}
          </Interview.Contents>
        </Interview>
      ))}
      {enableCopy && (
        <ClipButton iconSize="m" textSize="xs" wide="fill" marginTop="l" text={clipboardText} />
      )}
    </Box>
  );
});

OutpatientQuestionnaireSheet.displayName = 'OutpatientQuestionnaireSheet';
