import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { Property } from 'node_modules/csstype';
import React from 'react';
import { variant } from 'styled-system';

import { Flex, Text } from '~/components/blocks';
import { Size } from '~/styles/types';

type Props = {
  label: string;
  count: number;
  fontSize?: Size;
  lineHeight?: Property.LineHeight;
  width: string;
};

const Root = styled(Flex)<{ width: string }>(({ width }) =>
  css({
    alignItems: 'center',
    justifyContent: 'center',
    width,
  }),
);

const Badge = styled('div', { shouldForwardProp })<{ color?: 'grey' }>(
  ({ theme }) =>
    css({
      backgroundColor: theme.colors.colorPallete.pink,
      borderRadius: '50%',
      color: theme.colors.text.white,
      fontSize: theme.fontSizes.xs,
      minWidth: `calc(${theme.space.l} + ${theme.space.xs})`,
      height: `calc(${theme.space.l} + ${theme.space.xs})`,
      lineHeight: `calc(${theme.space.l} + ${theme.space.xs})`,
      textAlign: 'center',
      fontWeight: 'bold',
    }),
  ({ theme }) =>
    variant({
      prop: 'color',
      variants: {
        grey: css({
          backgroundColor: theme.colors.colorPallete.grey03,
        }),
      },
    }),
);

export const TabLabel = (props: Props) => {
  const theme = useTheme();
  return (
    <Root width={props.width}>
      <Text mr={theme.space.s} size={props.fontSize ?? 'm'} lineHeight={props.lineHeight}>
        {props.label}
      </Text>
      {props.count > 0 ? <Badge>{props.count}</Badge> : <Badge color="grey">0</Badge>}
    </Root>
  );
};
