import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Flex, Pagination } from '~/components/blocks';
import { entryNotificationDrawerState } from '~/state/partials/entry_notification_drawer/atoms';

import { NotificationPanelBase } from '../NotificationPanelBase';
import { EntryNotificationList } from './EntryNotificationList';

export const EntryNotificationPanel = () => {
  const [state, setState] = useRecoilState(entryNotificationDrawerState);
  const handleChangePage = useCallback(
    (page: number) => setState((state) => ({ ...state, page })),
    [setState],
  );

  return (
    <NotificationPanelBase>
      <NotificationPanelBase.Content>
        <EntryNotificationList />
      </NotificationPanelBase.Content>
      {state.totalCount > 0 && (
        <NotificationPanelBase.Footer>
          <Flex justifyContent="center">
            <Pagination
              currentPage={state.page}
              totalPage={state.totalPage}
              onChange={handleChangePage}
            />
          </Flex>
        </NotificationPanelBase.Footer>
      )}
    </NotificationPanelBase>
  );
};
