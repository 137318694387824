import { atom } from 'recoil';

import { EntryNotificationDrawer } from './types';

export const entryNotificationDrawerState = atom<EntryNotificationDrawer>({
  key: 'partials.entryNotificationDrawer',
  default: {
    page: 1,
    perPage: 6,
    totalPage: 1,
    totalCount: 0,
  },
});
