import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Flex, Icon, Text } from '~/components/blocks';
import { ExternalLink } from '~/components/blocks/ExternalLink';

const BoldSpan = styled('span')(() =>
  css({
    fontWeight: 'bold',
  }),
);

export const UberDeliveryFreePromotionText = React.memo(() => {
  const theme = useTheme();

  return (
    <Flex alignItems="center" marginTop={theme.space.m}>
      <Icon color="blue" icon="info" size="m" marginRight={theme.space.s} />
      <Text size="s">
        キャンペーン適用対象につき、今回の当日配達利用料は<BoldSpan>無料</BoldSpan>
        となります（
        <ExternalLink url="https://intercom.help/pharms/ja/articles/9765203">
          キャンペーン詳細
        </ExternalLink>
        <Icon
          icon="blank"
          size="m"
          marginLeft={theme.space.s}
          marginRight={theme.space.s}
          color="linkBlue"
        />
        ）
      </Text>
    </Flex>
  );
});

UberDeliveryFreePromotionText.displayName = 'UberDeliveryFreePromotionText';
