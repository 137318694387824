import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';

import { Box, Checkbox as DefaultCheckbox, Flex, List, ScrollBox } from '~/components/blocks';
import {
  MedicineNoteNotificationFragment,
  OnetimeApiCacheFragment,
  TemporaryPermissionFragment,
} from '~/graphql';
import { getMe } from '~/graphql/utility';
import { Label } from '~/utils/label';

import { ExpiredMedicineNoteNotification } from './ExpiredMedicineNoteNotification';
import { MedicineNoteNotification } from './MedicineNoteNotification';
import { Notification, OnetimeApiCache, TemporaryPermission } from './types';
import { useFetchDisclosedMedicineNotes } from './use-fetch-disclosed_medicine_notes';

const UNKNOWN_NAME = '氏名：---';

const toOnetimeApiCache = (
  cache: OnetimeApiCacheFragment,
  expiresAt: string,
  createdAt: string,
): OnetimeApiCache => {
  const profile = cache.patientProfile;

  return {
    expiresAt,
    createdAt,
    type: 'OnetimeApiCache',
    onetimeCode: cache.onetimeCode,
    active: cache.active,
    patientProfile: {
      name: profile.name ? profile.name.replace('　', '') : UNKNOWN_NAME, // 姓名の間に全角空白があるため、取り除く
      kana: profile.kana ? profile.kana.replace('　', '') : UNKNOWN_NAME, // 姓名の間に全角空白があるため、取り除く
    },
  };
};

const toTemporaryPermission = (
  permission: TemporaryPermissionFragment,
  expiresAt: string,
  createdAt: string,
): TemporaryPermission => {
  const patient = permission.patient;

  return {
    expiresAt,
    createdAt,
    type: 'TemporaryPermission',
    isRead: permission.isRead,
    temporaryPermissionId: permission.id,
    patientId: patient?.id || '',
    active: permission.active,
    patientProfile: {
      name: patient ? `${patient.familyName}${patient.givenName}` : UNKNOWN_NAME,
      kana: patient ? `${patient.phoneticFamilyName}${patient.phoneticGivenName}` : UNKNOWN_NAME,
    },
  };
};

const makeNotification = (note: MedicineNoteNotificationFragment) => {
  const expiresAt = `${Label.YYYYMMDDja(note.expiresAt)} ${Label.HHMM(note.expiresAt)}`;
  const createdAt = `${Label.YYYYMMDDja(note.createdAt)} ${Label.HHMM(note.createdAt)}`;

  if (note.__typename === 'HiccupOnetimeApiCache') {
    return toOnetimeApiCache(note, expiresAt, createdAt);
  }
  if (note.__typename === 'HiccupTemporaryPermission') {
    return toTemporaryPermission(note, expiresAt, createdAt);
  }
};

const Checkbox = styled(DefaultCheckbox)(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
  }),
);

export const MedicineNoteNotificationList = () => {
  const theme = useTheme();

  const { loading, scrollRef, data, isOnlyUnread, handleGetOnlyUnread } =
    useFetchDisclosedMedicineNotes();

  const nodes = useMemo(
    () => (data ? getMe(data)?.organization.disclosedMedicineNotes.nodes || [] : []),
    [data],
  );
  const notifications: Notification[] = useMemo(() => {
    if (!nodes) return [];

    return nodes
      .map((note) => makeNotification(note))
      .filter((notification): notification is Notification => notification !== undefined);
  }, [nodes]);

  return (
    <Box marginTop={theme.space.m} marginBottom={0} overflow="auto">
      <ScrollBox ref={scrollRef} loading={loading}>
        <Flex paddingY={theme.space.m} justifyContent="flex-end">
          <Checkbox label="未確認のみ表示" checked={isOnlyUnread} onChange={handleGetOnlyUnread} />
        </Flex>
        {!loading && notifications.length === 0 ? (
          <Flex justifyContent="center">
            <Box padding={theme.space.l}>お薬手帳の開示通知はありません</Box>
          </Flex>
        ) : (
          <List selectable>
            {notifications.map((notification, i) =>
              notification.active ? (
                <MedicineNoteNotification key={i} notification={notification} />
              ) : (
                <ExpiredMedicineNoteNotification key={i} notification={notification} />
              ),
            )}
          </List>
        )}
      </ScrollBox>
    </Box>
  );
};
