import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Button, Flex, Text } from '~/components/blocks';
import { ExternalLink } from '~/components/blocks/ExternalLink';
import { AppointmentDeliveryMethod } from '~/graphql';

import { DeliveryMethodField } from '../DeliveryMethodField';

type Props = {
  onClick?: () => void;
  children?: React.ReactNode;
  deliveryMethod: AppointmentDeliveryMethod;
};

const Root = styled('div')(({ theme }) =>
  css({
    borderRadius: theme.radii.default,
    background: theme.colors.background.bg,
    marginTop: theme.space.m,
    marginBottom: theme.space.m,
  }),
);

export const ReceiveOption = (props: Props) => {
  const { children, onClick } = props;
  const theme = useTheme();

  return (
    <Root>
      <Flex alignItems="center" padding={theme.space.m} justifyContent="space-between">
        <Flex alignItems="center">
          <Text size="xs" color="grey01">
            受け渡し方法：
          </Text>
          <DeliveryMethodField deliveryMethod={props.deliveryMethod} size="s" />
        </Flex>
        {onClick && (
          <Button use="default" size="s" onClick={onClick} marginLeft="auto">
            変更
          </Button>
        )}
      </Flex>
      {children}
      {props.deliveryMethod === 'same_day_delivery' && (
        <Flex justifyContent="flex-end" paddingBottom={theme.space.s} paddingRight={theme.space.m}>
          <Text size="s">
            <ExternalLink url="https://intercom.help/pharms/ja/articles/9049950-%E5%BD%93%E6%97%A5%E9%85%8D%E9%81%94%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E3%81%AE%E6%93%8D%E4%BD%9C%E6%96%B9%E6%B3%95%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6#:~:text=%E6%93%8D%E4%BD%9C%E6%96%B9%E6%B3%95%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6-,%E2%97%BC%EF%B8%8F%E5%BD%93%E6%97%A5%E9%85%8D%E9%81%94%E5%AF%BE%E5%BF%9C%E6%99%82%E3%81%AE%E6%B3%A8%E6%84%8F%E4%BA%8B%E9%A0%85,-%E5%BD%93%E6%A9%9F%E8%83%BD%E3%81%AE">
              ※事前に注意事項をご確認ください
            </ExternalLink>
          </Text>
        </Flex>
      )}
    </Root>
  );
};
