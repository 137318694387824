import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useMemo } from 'react';

import { Box, Checkbox as DefaultCheckbox, Flex, List, ScrollBox } from '~/components/blocks';
import { getMe } from '~/graphql/utility';
import { usePreviousValue } from '~/hooks/use-previous-value';

import { Notification } from './Notification';
import { useFetchAnsweredQuestionnaireSheets } from './use-fetch-answered_questionnaire_sheets';

const ListRoot = styled(List)(() =>
  css({
    borderTop: 'none',
  }),
);

const Checkbox = styled(DefaultCheckbox)(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
  }),
);

export const NotificationList = () => {
  const { data, loading, scrollRef, isOnlyUnread, handleGetOnlyUnread } =
    useFetchAnsweredQuestionnaireSheets();
  const theme = useTheme();

  const prevData = usePreviousValue(data);
  const currentData = data || prevData;
  const sheets = useMemo(
    () =>
      currentData
        ? getMe(currentData)?.organization.medicationFollowupQuestionnaireSheets.nodes || []
        : [],
    [currentData],
  );

  return (
    <Box overflow="auto" height="100%">
      <ScrollBox ref={scrollRef} loading={loading}>
        <Flex paddingY={theme.space.m} justifyContent="flex-end">
          <Checkbox label="未確認のみ表示" checked={isOnlyUnread} onChange={handleGetOnlyUnread} />
        </Flex>
        {!loading && sheets.length === 0 ? (
          <Flex justifyContent="center">
            <Box padding={theme.space.l}>質問票の回答通知はありません</Box>
          </Flex>
        ) : (
          <ListRoot selectable>
            {sheets.map((sheet, idx) => (
              <Notification key={idx} sheet={sheet} />
            ))}
          </ListRoot>
        )}
      </ScrollBox>
    </Box>
  );
};
