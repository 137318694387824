import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Box, Tabs } from '~/components/blocks';
import { MedicationFollowupNotificationPanel } from '~/components/layouts/SharedAppShell/MedicationFollowupNotificationPanel';
import { MedicineNoteNotificationPanel } from '~/components/layouts/SharedAppShell/MedicineNoteNotificationPanel';
import { TabLabel } from '~/components/layouts/SharedAppShell/NotificationPanel/TabLabel';
import { OrganizationNotificationPanel } from '~/components/layouts/SharedAppShell/OrganizationNotificationPanel';
import { PlanFeatureCode } from '~/graphql';
import { useCheckinSetting } from '~/hooks/use-checkin-setting';
import { useFetchOutpatientQuestionnaireSetting } from '~/hooks/use-fetch_outpatient_questionnaire_setting';
import { usePlanFeature } from '~/hooks/use-plan-feature';
import { notificationState } from '~/state/layouts/SharedAppShell/atoms';

import { EntryNotificationPanel } from '../EntryNotificationPanel';

const Root = styled('div')(({ theme }) =>
  css({
    padding: theme.space.m,
    width: '464px',
    height: `calc(80vh - ${theme.layouts.appHeader.height})`,
    maxHeight: '660px',
  }),
);

const Content = styled(Box)(() =>
  css({
    height: 'calc(100% - 60px)',
    overflow: 'auto',
  }),
);

const NotificationTab = styled(Tabs.Tab)(({ theme }) =>
  css({
    padding: `${theme.space.l} 0`,
  }),
);

export const NotificationPanel = () => {
  const { receptionCount, entryCount, followupCount, medicineNoteCount } =
    useRecoilValue(notificationState);
  const [tab, setTab] = useState(() => {
    if (receptionCount > 0) return 'Reception';
    if (entryCount > 0) return 'Entry';
    if (followupCount > 0) return 'MedicationFollowup';
    if (medicineNoteCount > 0) return 'MedicineNote';
    return 'Reception';
  });
  const { hasEnabledFeature: hasEnabledFollowup } = usePlanFeature(PlanFeatureCode.Followup);

  const { isActive: enabledCheckin } = useCheckinSetting();
  const { active: enabledOutpatientQuestionnaire } = useFetchOutpatientQuestionnaireSetting();
  const enabledEntry = enabledCheckin || enabledOutpatientQuestionnaire;
  const tabsLength =
    hasEnabledFollowup && enabledEntry ? 4 : hasEnabledFollowup || enabledEntry ? 3 : 2;
  const tabWidth = tabsLength === 4 ? '110px' : tabsLength === 3 ? '146px' : '220px';

  const handleChangeTab = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const element = e.currentTarget as HTMLButtonElement;
      const newTab = element.dataset['tab'] as string;

      setTab(newTab);
    },
    [setTab],
  );

  return (
    <Root>
      <Tabs>
        <NotificationTab
          active={tab === 'Reception'}
          data-tab={'Reception'}
          onClick={handleChangeTab}
        >
          <TabLabel label="受付" count={receptionCount} width={tabWidth} />
        </NotificationTab>
        {enabledEntry && (
          <NotificationTab active={tab === 'Entry'} data-tab={'Entry'} onClick={handleChangeTab}>
            <TabLabel
              label={`チェックイン
              Web問診`}
              count={entryCount}
              fontSize="s"
              width={tabWidth}
              lineHeight="1.3em"
            />
          </NotificationTab>
        )}
        {hasEnabledFollowup && (
          <NotificationTab
            active={tab === 'MedicationFollowup'}
            data-tab={'MedicationFollowup'}
            onClick={handleChangeTab}
          >
            <TabLabel label="質問票" count={followupCount} width={tabWidth} />
          </NotificationTab>
        )}
        <NotificationTab
          active={tab === 'MedicineNote'}
          data-tab={'MedicineNote'}
          onClick={handleChangeTab}
        >
          <TabLabel label="お薬手帳" count={medicineNoteCount} width={tabWidth} />
        </NotificationTab>
      </Tabs>
      <Content>
        {tab === 'Reception' && <OrganizationNotificationPanel />}
        {tab === 'Entry' && <EntryNotificationPanel />}
        {tab === 'MedicationFollowup' && <MedicationFollowupNotificationPanel />}
        {tab === 'MedicineNote' && <MedicineNoteNotificationPanel />}
      </Content>
    </Root>
  );
};
