
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AllPatient": [
      "CheckinEntry",
      "DirectVisitor",
      "Patient",
      "WebVisitor"
    ],
    "DirectVisitorCandidate": [
      "CheckinEntry",
      "DirectVisitor"
    ],
    "DisclosedMedicineNote": [
      "HiccupOnetimeApiCache",
      "HiccupTemporaryPermission"
    ],
    "Event": [
      "GuestPatientMedicationFollowupComment",
      "MedicationFollowupMessage",
      "MedicationFollowupQuestionnaireSheet",
      "PatientMedicationFollowupComment",
      "TracingReport"
    ],
    "FeatureTarget": [
      "Company",
      "Organization",
      "Patient",
      "Practitioner"
    ],
    "FeeSetting": [
      "CompanyFeeSetting",
      "OrganizationCompanyFeeSetting",
      "OrganizationFeeSetting"
    ],
    "GuestPatient": [
      "DirectVisitor",
      "WebVisitor"
    ],
    "LoginUser": [
      "Operator",
      "Practitioner"
    ],
    "Node": [
      "Address",
      "Agent",
      "AgreementExtension",
      "AllowList",
      "Announcement",
      "Appointment",
      "B2cloudSetting",
      "BankAccount",
      "Bookmark",
      "Charge",
      "ChargeHistory",
      "CheckinEntry",
      "CheckinOrganization",
      "ClientAuthentication",
      "ClientCert",
      "ClientCertJobInformation",
      "Company",
      "CompanyAddress",
      "CompanyCommonSetting",
      "CompanyContactPoint",
      "CompanyFeeSetting",
      "CompanyPractitioner",
      "CompanyPractitionerConfirmation",
      "CompanyPractitionerContactPoint",
      "CompanySessionSetting",
      "CompanySetupDocument",
      "CompanyWebBookingsLimitSetting",
      "ContactPoint",
      "Contract",
      "ContractPlan",
      "DirectVisitor",
      "DraftAppointment",
      "Encounter",
      "Extension",
      "FaximoSendFax",
      "Feature",
      "FeatureTargetGroup",
      "FeatureUsage",
      "GendaApiKey",
      "GendaSetting",
      "GuestPatientMedicationFollowupComment",
      "HiccupAppointmentPermission",
      "HiccupOnetimeApiCache",
      "HiccupTemporaryPermission",
      "InvitationCode",
      "Invoice",
      "MedicalInstitutionSetting",
      "MedicationFollowupAutoReplySetting",
      "MedicationFollowupAutoReplySettingTemplate",
      "MedicationFollowupDraftMessage",
      "MedicationFollowupMessage",
      "MedicationFollowupMessageTemplate",
      "MedicationFollowupMessageTemplateJobInformation",
      "MedicationFollowupQuestionnaire",
      "MedicationFollowupQuestionnaireRevision",
      "MedicationFollowupQuestionnaireSheet",
      "MedicationFollowupSetting",
      "MedixsPatient",
      "MedixsReception",
      "MedixsSetting",
      "MessageDeliver",
      "MessageDeliverResult",
      "Operator",
      "OperatorAccessPermission",
      "OperatorRole",
      "Organization",
      "OrganizationAddress",
      "OrganizationCompanyFeeSetting",
      "OrganizationContactPoint",
      "OrganizationFeeSetting",
      "OrganizationImportJobInformation",
      "OrganizationNotification",
      "OrganizationNotificationSetting",
      "OutpatientQuestionnaire",
      "OutpatientQuestionnaireEntry",
      "OutpatientQuestionnaireOrganization",
      "OutpatientQuestionnaireQuestion",
      "OutpatientQuestionnaireSetting",
      "OutpatientQuestionnaireSettingQuestionnaire",
      "Patient",
      "PatientAttachment",
      "PatientMedicationFollowupComment",
      "Payout",
      "PayoutBankAccount",
      "PayoutOrganization",
      "PayoutSubject",
      "PfDispensingRequest",
      "PfPatient",
      "PfPrescriptionImage",
      "Plan",
      "PlanFeature",
      "Practitioner",
      "PractitionerOtpSecret",
      "PractitionerSigninHistory",
      "PrivilegeOrganization",
      "PrivilegeOrganizationPractitioner",
      "PublicAddress",
      "PublicAppointment",
      "PublicCheckinEntry",
      "PublicMedicationFollowupMessage",
      "PublicMedicationFollowupQuestionnaireRevision",
      "PublicMedicationFollowupQuestionnaireSheet",
      "PublicOrganization",
      "PublicOrganizationAddress",
      "PublicOutpatientQuestionnaireEntry",
      "PublicSignupPfPatientInformation",
      "PublicSignupPfPatientInformationOrganization",
      "PublicSlot",
      "PublicWebBookingsSetting",
      "RefundHistory",
      "SendgridEventActivity",
      "TracingReport",
      "TracingReportDraft",
      "UberDelivery",
      "UberOrganization",
      "UberOrganizationImportJobInformation",
      "UberOrganizationSetting",
      "UberOrganizationSettingJobInformation",
      "UberSignature",
      "UberSignatureDraft",
      "Visit",
      "WebBookingsBusinessHour",
      "WebBookingsExceptionBusinessHour",
      "WebBookingsExceptionDate",
      "WebBookingsHolidayBusinessHour",
      "WebBookingsLimit",
      "WebBookingsSetting",
      "WebBookingsSuspend",
      "WebVisitor"
    ],
    "Reception": [
      "Appointment",
      "CheckinEntry",
      "DraftAppointment",
      "OutpatientQuestionnaireEntry"
    ]
  }
};
      export default result;
    